import {useRadium} from "@radiumjs/vue";
import {CrudRepository, RadiumClient} from "@radiumjs/client";
import {useStore} from "@/store";

export class _GET {

    static provider: RadiumClient;

    constructor(){}

    static setProvider(radium:RadiumClient = null){
        if(!_GET.provider){
            if(radium){
                _GET.provider = radium;
            }else{
                let rad = useRadium();
                _GET.provider = rad;
            }
        }
    }

    static async all(service: string, _args: any = {}, _filters: any = {}){
        _GET.setProvider();
        const default_args = {rows: 1000,first: 0};
        const args = {...default_args,..._args};

        const crude = new CrudRepository(_GET.provider, service);
        const datas = await crude.list(_filters,args).then(function (response) {
            return response.items;
        });
        return datas;
    }

    static async get(service: string, id: string){
        _GET.setProvider();
        const crude = new CrudRepository(_GET.provider, service);
        const data = await crude.get(id).then(function (response) {
            return response;
        }).catch(function(e){
            //console.log(typeof(e));
            //console.log(e.response.status);
            /*if(e.match(/code 404/)){

            }*/
            return {};
        });
        return data;
    }

    static async update(service: string, id: string, key: any, value: string){
        _GET.setProvider();
        const crude = new CrudRepository(_GET.provider, service);
        let op = {};
        if(typeof(key)=='string'){
            op[key] = value;
        }else{
            op = key;
        }

        const data = await crude.update(id,{'$set':op}).then(function (response) {
            return response;
        });
        return data;
    }

    static async create(service: string, key: any, value: string = ''){
        _GET.setProvider();
        const crude = new CrudRepository(_GET.provider, service);
        let op = {};
        if(typeof(key)=='string'){
            op[key] = value;
        }else{
            op = key;
        }

        const data = await crude.create(op).then(function (response) {
            return response;
        });
        return data;
    }

    static async update_merge(service: string, ids: [], key: string, value: string){
        _GET.setProvider();
        const crude = new CrudRepository(_GET.provider, service);
        ids.forEach(id => {
            const item = _GET.get(service,id).then(function (response) {
                console.log(key+' '+value);
                if(response['_id']){
                    let current = response[key] || [];
                    if(typeof(current)!='object'){
                        current = [current];
                    }
                    if(current.includes(value)){
                        const index = current.findIndex(item => item==value);
                        if(index>-1){
                            current.splice(index, 1);
                        }
                    }else{
                        current = [...current, value];
                    }
                    _GET.update(service,response['_id'],key,current);
                }
                return response;
            });

        });
    }

}
